import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61c40cf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  key: 0,
  class: "p-menubar p-component p-d-flex p-jc-center"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "p-menubar p-component p-d-flex p-jc-between"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "p-d-flex" }
const _hoisted_7 = { class: "p-mx-2" }
const _hoisted_8 = { class: "p-mx-2" }
const _hoisted_9 = { class: "p-mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarCat = _resolveComponent("SidebarCat")!
  const _directive_badge = _resolveDirective("badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isOrder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _ctx.logo,
              height: "40",
              class: "p-mr-2"
            }, null, 8, _hoisted_3)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isOrder == false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              alt: "logo",
              src: _ctx.logo,
              height: "40",
              class: "p-mr-2",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getToHomePage && _ctx.getToHomePage(...args)))
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("i", {
                class: "pi pi-search p-text-bold icon-header pointer",
                style: _normalizeStyle({ color: _ctx.color }),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSerachProduct && _ctx.onSerachProduct(...args)))
              }, null, 4)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.quantity > 0)
                ? _withDirectives((_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "pi pi-shopping-cart p-text-bold icon-header pointer danger",
                    style: _normalizeStyle([{"font-size":"2rem"}, { color: _ctx.color }]),
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCheckoutCart && _ctx.onCheckoutCart(...args)))
                  }, null, 4)), [
                    [
                      _directive_badge,
                      _ctx.quantity,
                      void 0,
                      { danger: true }
                    ]
                  ])
                : _createCommentVNode("", true),
              (_ctx.quantity == 0)
                ? _withDirectives((_openBlock(), _createElementBlock("i", {
                    key: 1,
                    class: "pi pi-shopping-cart p-text-bold icon-header pointer danger",
                    style: _normalizeStyle([{"font-size":"2rem"}, { color: _ctx.color }]),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onCheckoutCart && _ctx.onCheckoutCart(...args)))
                  }, null, 4)), [
                    [
                      _directive_badge,
                      0,
                      void 0,
                      { danger: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("i", {
                class: "pi pi-bars p-text-bold icon-header pointer",
                style: _normalizeStyle({ color: _ctx.color }),
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setSidebarVisible && _ctx.setSidebarVisible(...args)))
              }, null, 4)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_SidebarCat, { ref: "sidebarCat" }, null, 512)
  ]))
}