import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77955fc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container shadow" }
const _hoisted_2 = {
  key: 0,
  class: "loading-panel"
}
const _hoisted_3 = { class: "p-as-center p-m-auto" }
const _hoisted_4 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalLoading = _resolveComponent("ModalLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"60px","height":"60px"},
              strokeWidth: "5",
              fill: "#ffffff",
              animationDuration: ".8s"
            })
          ])
        ]))
      : (!_ctx.isLoading && _ctx.brandId !== 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Header)
            ]),
            _createElementVNode("div", {
              id: "content",
              class: _normalizeClass([
          {
            'return-body': _ctx.$route.path.includes('return'),
          },
        ])
            }, [
              (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$route.fullPath
              }))
            ], 2)
          ], 64))
        : _createCommentVNode("", true),
    (_ctx.componentLoading)
      ? (_openBlock(), _createBlock(_component_ModalLoading, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}